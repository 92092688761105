import ServicePresentation from "../../../../composit/servicePresentation/ServicePresentation";
import Slide from "../../../../utils/slide/Slide";
import "./WhyUsTextSection.css";
const checkImg = require("../../../../assets/check.png");

const WhyUsTextSection = () => {
  return (
    <div>
      <div className="why-us-text-section-container">
        <div>
          <h5 className="red-header">Pogodnosti</h5>
          <h1>Zasto odabrati baš nas?</h1>
          <h5 className="why-us-header">
            Alu-Still se izdvaja svojom brzom i efikasnom uslugom, preciznim
            rokovima i jednostavnom saradnjom, kao i upotrebom materijala
            renomiranih nemačkih proizvođača. Kao porodična kompanija našim
            kupcima omogućavamo usluge izrade i servisiranje stolarije preko 20
            godina.
          </h5>
          <div>
            <Slide>
              <div>
                <ServicePresentation
                  imgName="check"
                  imgSrc={checkImg}
                  title="Skidanje mera i savetovanje ulazi u cenu stolarije"
                  text="Našim kupcima nudimo mogućnost skidanja mera , savetovanje i pronalaženja optimalnog rešenja za izradu nove stolarije vašeg doma."
                />
              </div>
            </Slide>
            <Slide>
              <ServicePresentation
                imgName="check"
                imgSrc={checkImg}
                title="Besplatna montaža nove stolarije"
                text="Sva stolarija koju ugrađujemo se posebno izrađuje po meri i željama kupaca, Kod nas je montaža besplatna kako bi se kupcima obezbedio najbolji mogući kvalitet usluga."
              />
            </Slide>
            <Slide>
              <ServicePresentation
                imgName="check"
                imgSrc={checkImg}
                title="Besplatna demontaža stare stolarije"
                text="Vršimo usluge demontaže Vaše postojeće stolarije i po potrebi odnošenje iste kako bismo naše kupce poštedeli dodatnih troškova. Za nama Vam ostaju samo molerski radovi, obrade špaletni."
              />
            </Slide>
            <Slide>
              <ServicePresentation
                imgName="check"
                imgSrc={checkImg}
                title="Doživotna mogućnost i servisiranje stolarije"
                text="Kao porodična kompanija na tržištu smo već 20 godina i nameravamo da tu ostanemo. Shodno tome nasi kupci imaju mogucnost doživotnog održavanja stolarije."
              />
            </Slide>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUsTextSection;
