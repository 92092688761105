export interface ILocationVar {
  img: string;
  preTitle: string;
  title: string;
  text: string;
  adress: string;
  email: string;
  phoneNumber: string;
  location: string;
}
const imgReq = require("../../../assets/apartment.jpg");
export const FirstLocationVar: ILocationVar = {
  img: imgReq,
  preTitle: "Lokacija 1 ",
  title: "Proizvodnja",
  text: "Posetite ili pozovite našu proizvodnju i saznajte sve što Vas interesuje Vezano za stolariju.",
  adress: "Slobodana Macure 1, Sombor",
  email: "alustillns@gmail.com",
  phoneNumber: "063 / 104 11 38",
  location:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11130.308585855288!2d19.083808787158205!3d45.779664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475cca777bc558eb%3A0x98c8bdf5b560836!2sEtno-Stil%20025!5e0!3m2!1sen!2srs!4v1700426847375!5m2!1sen!2srs",
};

export const SecondLocationVar: ILocationVar = {
  img: imgReq,
  preTitle: "Lokacija 2 ",
  title: "Prodaja i podrška",
  text: "Slobodno nas kontaktirajte i saznajte sve što Vas interesuje vezano za stolariju.",
  adress: "Svetozara Markovića 71, Kać",
  email: "alustillns@gmail.com",
  phoneNumber: "063 / 87 14 363",
  location:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2806.0077953884474!2d19.938237176599895!3d45.30826814382887!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475b1995803a50bb%3A0xcf7753680036e9d7!2z0KHQstC10YLQvtC30LDRgNCwINCc0LDRgNC60L7QstC40ZvQsCA3MSwg0JrQsNGb!5e0!3m2!1ssr!2srs!4v1728503287433!5m2!1ssr!2srs",
};
