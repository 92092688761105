import Reveal from "../../../../utils/reveal/Reveal";
import ServicePresentation from "../../../../composit/servicePresentation/ServicePresentation";
import "./OurServicesTextSection.css";
const drill = require("../../../../assets/drill.png");
const windowPicture = require("../../../../assets/window.png");
const door = require("../../../../assets/door.png");
const doorKnob = require("../../../../assets/doorknob.png");

const OurServicesTextSection = () => {
  return (
    <div className="our-services-text-container">
      <div>
        <h5 className="red-header">Dobrodošli u Alu-Still</h5>
        <Reveal>
          <h1>Naše usluge</h1>
        </Reveal>
        <h5 className="our-services-header">
          Alu-Still se izdvaja svojom brzom i efikasnom uslugom, preciznim
          rokovima i jednostavnom saradnjom, kao i upotrebom materijala
          renomiranih nemačkih proizvođača. Kao porodična kompanija našim
          kupcima omogućavamo usluge izrade i servisiranje stolarije preko 20
          godina.
        </h5>
      </div>
      <div>
        <div>
          <Reveal>
            <div className="service-presentation-section">
              <ServicePresentation
                imgName="windowPicture"
                imgSrc={windowPicture}
                text="Stolarija izrađena od Salamender PVC profila, Siegenia okovom i italijanskim staklom punjenim argonom."
                title="PVC Stolarija"
              />
              <ServicePresentation
                imgName="drill"
                imgSrc={drill}
                text="Naši kupci su osigurani uslugama servisiranja stolarije kao i savetovanjem vezano za izbor, upotrebu i prateće tehnikalije."
                title="Servisiranje i savetovanje"
              />
            </div>
          </Reveal>
          <Reveal>
            <div className="service-presentation-section">
              <ServicePresentation
                imgName="door"
                imgSrc={door}
                text="Stolarija izrađena od Alumil i Elvial aluminijumskih profila, Siegenia okovom i italijanskim staklom punjenim argonom."
                title="Aluminijumska stolarija"
              />
              <ServicePresentation
                imgName="doorKnob"
                imgSrc={doorKnob}
                text="Stolarija izrađena od Alumil i Elvial aluminijumskih profila, Siegenia okovom i italijanskim staklom punjenim argonom."
                title="Prateća oprema"
              />
            </div>
          </Reveal>
        </div>
      </div>
    </div>
  );
};
export default OurServicesTextSection;
