import PictureSlider, {
  IPicture,
} from "../../../composit/pictureSlider/PictureSlider";
import "./ProjectGalerySection.css";

// const builder1 = require("../../../assets/builder1.jpg");
// const builder2 = require("../../../assets/builder2.jpg");
// const builder3 = require("../../../assets/builder3.jpg");
// const builder4 = require("../../../assets/builder4.jpg");
// const builder5 = require("../../../assets/builder5.jpg");

const builder1 = require("../../../assets/gallery/1.jpg");
const builder2 = require("../../../assets/gallery/2.jpg");
const builder3 = require("../../../assets/gallery/3.jpg");
const builder4 = require("../../../assets/gallery/4.jpg");
const builder5 = require("../../../assets/gallery/5.jpg");
const builder6 = require("../../../assets/gallery/2_1.jpg");
const builder7 = require("../../../assets/gallery/2_2.jpg");
const builder8 = require("../../../assets/gallery/6.jpg");
const builder9 = require("../../../assets/gallery/7.jpg");
const builder10 = require("../../../assets/gallery/9.jpg");
const builder11 = require("../../../assets/gallery/10.jpg");
const builder12 = require("../../../assets/gallery/11.jpg");
const builder13 = require("../../../assets/gallery/15.jpg");
const builder14 = require("../../../assets/gallery/IMG-3c8d3c43a76eff4b0a65bb90c982504f-V.jpg");
const builder15 = require("../../../assets/gallery/IMG-30464bd2e6175b578048de554c4112ea-V.jpg");

const ProjectGalerySection = () => {
  // const pictures: IPicture[] = [
  //   {
  //     name: "builder1",
  //     source: builder1,
  //   },
  //   {
  //     name: "builder2",
  //     source: builder2,
  //   },
  //   {
  //     name: "builder4",
  //     source: builder4,
  //   },
  //   {
  //     name: "builder3",
  //     source: builder3,
  //   },
  //   {
  //     name: "builder5",
  //     source: builder5,
  //   },
  // ];
  const pictures1: IPicture[] = [
    {
      name: "builder1",
      source: builder1,
    },

    {
      name: "builder3",
      source: builder3,
    },

    {
      name: "builder10",
      source: builder10,
    },

    {
      name: "builder4",
      source: builder4,
    },

    {
      name: "builder7",
      source: builder7,
    },
    {
      name: "builder5",
      source: builder5,
    },
    {
      name: "builder6",
      source: builder6,
    },

    {
      name: "builder9",
      source: builder9,
    },
    {
      name: "builder8",
      source: builder8,
    },
    {
      name: "builder9",
      source: builder9,
    },
    {
      name: "builder11",
      source: builder11,
    },
    {
      name: "builder2",
      source: builder2,
    },
    {
      name: "builder11",
      source: builder11,
    },
    {
      name: "builder12",
      source: builder12,
    },
    {
      name: "builder13",
      source: builder13,
    },
    {
      name: "builder14",
      source: builder14,
    },
    {
      name: "builder13",
      source: builder13,
    },
    {
      name: "builder15",
      source: builder15,
    },
  ];

  return (
    <div>
      <div className="project-galery-section-container ">
        <div className="project-galery-section-container-inner sizer">
          <PictureSlider pictures={pictures1} />
        </div>
      </div>
    </div>
  );
};
export default ProjectGalerySection;
