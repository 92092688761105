import Button from "../../../common/button/Button";
import Reveal from "../../../utils/reveal/Reveal";
import "./RedirectionSection.css";
import { useNavigate } from "react-router-dom";

const RedirectionSection = () => {
  const navigate = useNavigate();

  const changeUrl = () => {
    // Use the navigate function to navigate to a new URL
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  return (
    <div className="redirection-section-container">
      <div className="redirection-section-container-inner sizer">
        <Reveal>
          <h1>Da li vam je potrebna stolarija?</h1>
        </Reveal>
        <Reveal>
          <h5>
            Sve vaše želje i zamisli vezane za stolariju možete nam predočiti
            putem kontakt forme i naš tim će da Vam u najkraćem mogućem roku
            odgovoriti sa adekvatnim rešenjem i ponudom.
          </h5>
        </Reveal>
        <Reveal>
          <Button action={() => changeUrl()} name="Kontakt" />
        </Reveal>
      </div>
    </div>
  );
};
export default RedirectionSection;
