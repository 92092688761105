import { useState } from "react";
import "./ModalEmail.css";
import Button from "../../common/button/Button";
import Carpentry, { ICarpentry } from "./carpentry/Carpentry";
import sendMail from "../../utils/mail/MailSender";

interface IModalEmail {
  buttonName: string;
  isInverted?: boolean;

  // carpentryList: ICarpentry[];
}
interface ModalInputs {
  nameAndSurname: string;
  city: string;
  address: string;
  place: string;
  email: string;
  phone: string;
  message: string;
  carpentryList: ICarpentry[];
}

const ModalEmail = ({
  buttonName,
  isInverted = false,
}: // carpentryList,
IModalEmail) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const [carpentryList, setCarpentryList] = useState<ICarpentry[]>([
    {
      id: 1,
      carpentryType: "",
      wingNumber: "",
      equipmentList: [],
      amount: 0,
      materialType: "",
      width: 0,
      height: 0,
      color: "",
    },
  ]);

  const addCarpentry = () => {
    const newCarpentry: ICarpentry = {
      id: carpentryList.length + 1,
      carpentryType: "",
      wingNumber: "",
      equipmentList: [],
      amount: 0,
      materialType: "",
      width: 0,
      height: 0,
      color: "",
    };
    setCarpentryList([...carpentryList, newCarpentry]);
  };
  const removeCarpentry = (id: number) => {
    const updatedList = carpentryList.filter(
      (carpentry) => carpentry.id !== id
    );
    setCarpentryList(updatedList);
  };

  const updateCarpentry = (updatedCarpentry: ICarpentry) => {
    const updatedList = carpentryList.map((carpentry) => {
      if (carpentry.id === updatedCarpentry.id) {
        return updatedCarpentry;
      }
      return carpentry;
    });
    setCarpentryList(updatedList);
  };

  const [modalInputs, setModalInputs] = useState<ModalInputs>({
    address: "",
    place: "",
    city: "",
    email: "",
    nameAndSurname: "",
    phone: "",
    message: "",
    carpentryList: carpentryList,
  });
  const handleInChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const updatedObj = { ...modalInputs, [name]: value };
    setModalInputs(updatedObj);
  };
  const handleTxtChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    const updatedObj = { ...modalInputs, [name]: value };
    setModalInputs(updatedObj);
  };

  const convertModalInputsToString = (inputs: ModalInputs): string => {
    let body = `Ime i Prezime: ${inputs.nameAndSurname}\n`;
    body += `Grad: ${inputs.city}\n`;
    body += `Adressa: ${inputs.address}\n`;
    body += `Stan: ${inputs.place}\n`;
    body += `Email: ${inputs.email}\n`;
    body += `Telefon: ${inputs.phone}\n\n`;

    body += "List elemenata :\n";
    inputs.carpentryList.forEach((carpentry, index) => {
      body += `br: ${index + 1}:\n`;
      body += `Tip: ${carpentry.carpentryType}\n`;
      body += `Broj Krila: ${carpentry.wingNumber}\n`;
      body += `Količina: ${carpentry.amount}\n`;
      body += `Material: ${carpentry.materialType}\n`;
      body += `Širina: ${carpentry.width}\n`;
      body += `Visina: ${carpentry.height}\n`;
      body += `Boja: ${carpentry.color}\n\n`;
    });

    return body;
  };

  const handleSubmit = () => {
    // Call your function here, for example, sending an email
    sendMail(
      modalInputs.nameAndSurname,
      modalInputs.email,
      convertModalInputsToString(modalInputs),
      modalInputs.phone
    );
  };

  return (
    <>
      <Button name={buttonName} action={toggleModal} isInverted={isInverted} />

      {isOpen && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
            <button className="close-modal" onClick={toggleModal}>
              Zatvori
            </button>
            <div className="form-container">
              {carpentryList.map((carpentry, index) => (
                <Carpentry
                  index={index}
                  key={carpentry.id}
                  carpentry={carpentry}
                  removeCarpentry={removeCarpentry}
                  updateCarpentry={updateCarpentry}
                />
              ))}
              <button onClick={addCarpentry} className="add-button">
                Dodaj stolariju
              </button>
            </div>
            <span></span>
            <div className="contact">
              <div>
                <textarea
                  placeholder="Komentar"
                  name="message"
                  onChange={handleTxtChange}
                />
              </div>
              <div className="input-section">
                <div>
                  <input
                    type="text"
                    placeholder="Ime i Prezime"
                    name="nameAndSurname"
                    required
                    onChange={handleInChange}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Grad"
                    name="city"
                    required
                    onChange={handleInChange}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Adresa"
                    name="address"
                    required
                    onChange={handleInChange}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Sprat/Stan"
                    name="place"
                    required
                    onChange={handleInChange}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="E-Mail"
                    name="email"
                    required
                    onChange={handleInChange}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Telefon"
                    name="phone"
                    onChange={handleInChange}
                  />
                </div>
              </div>
            </div>
            <div className="subbmit-button">
              <Button
                action={() => {
                  handleSubmit();
                  setIsOpen(false);
                }}
                name="Pošalji poruku"
                key={1}
              ></Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalEmail;
