export interface IArticleVar {
  title: string;
  subTitle: string;
  articles: { title: string; date: string; text: string; photoSrc: string }[];
}
export const ArticleVar: IArticleVar = {
  title: "Česta pitanja",
  subTitle: "FAQ",
  articles: [
    {
      title: "Zašto se oseti strujanje hladnog vazduha oko prozora?",
      date: "",

      photoSrc: require("../../../../assets/builder6.jpg"),
      text: `Hladan vazduh koji struji oko stolarije je neprijatna i nažalost česta pojava i moguće je da se javi čak i kod nove stolarije. 
    Ova pojava je naročito uznemiravajuća u zimskim danima kada nam je ugodnost i toplina doma preko potrebna.
    
    U ovakvim situacijama potrebno je prvo utvditi da li vetar dolazi negde na spoju krila i štoka stolarije, jer uvek postoji mogućnost da se
    pomerila neka od zaprivnih guma, da neki od tuljaka ne zatvara kako treba i slično.
    Otklanjanje kvarova ovog tipa je brzo i jednostavno i uz stručan pristup popravci nema trajnih posledica po stolariju i udobnost prostorije.
    Ono sto je česća pojava u ovim situacijama, naročito nakon zamene postojeće stolarije novom, jeste da hladan vazduh prolazi na spojevima zida i 
    stolarije, a najčešće ispod ili sa bočnih strana.
    
    Problem nastaje pri neadekvatnoj obradi špaletni nakon ugradnje stolarije, gde se spojevi ovlaš zaptivaju raznim materijalima za završnu obradu.
    U ovom slučaju problem se rešava ponovnom obradom špaletni, gde se kako spolja, tako i iznutrna spojevi stolarije i zida obrađuju tako da budu 
    u potpunosti zaptiveni i prekriveni obradom (moguće su razne završne zidarske i molerske obrade, malterisanje, lepnjenje stirodura, 
    stiropora, itd.).`,
    },
    {
      title: "Zašto voda curi iz PVC ili aluminijumskog prozora?",
      date: "",
      photoSrc: require("../../../../assets/builder1-cut2.jpg"),
      text: `Iako Vam može delovati kao kvar, ukoliko se malo vode pojavi u donjem delu štoka Vašeg prozora ili kapa sa otvorenog krila vrata/prozora, 
      ovakve pojave su česte i normalne.
      Da biste locirali problem trebate potražiti u levom i desnom uglu donje stranice prozora ili praga vrata odvode za višak vode koja se 
      sliva niz stolariju.
      Razne reklame i celokupan marketing vezan za Alu i PVC stolariju Vas može ubediti da je Vaša stolarija vodootporna, trpi vodu pod pritiskom i 
      slično, dok je realnost da mala količina vode prolazi pored zaprivnih guma i da se sliva kroz profil na okapnicu.
      Ukoliko dodje do mehaničkog prljanja odliva i kanala voda će ostajati u štoku prozora i naposletku prelivati u prostoriju.
      
      Da biste izblegli ovakve situacije i moguće prljanje Vašeg zida pozeljno je povremeno čištenje ovih kanala tankim predmetom, tipa čačkalice,
      kineskim štapićem  i običnom vodom.`,
    },
    {
      title: "Zašto se pojavljuje vlaga sa unutrašnje strane prozora?",
      date: "",
      photoSrc: require("../../../../assets/builder7.jpg"),
      text: `Pojava vode, kondenza sa unutrašnje strane Vaše stolarije nije nužno loš znak i naprotiv može značiti da Vaši prozori dobro dihtuju.
      Vodene kapljice koje se skupljaju na staklima nove stolarije su zapravo akumulirana vlaga iz Vaše kuće ili stana. 
      Kuvanje, tuširanje, čak i boravak u prostoriji povećava vlažnost vazduha i ukoliko ne postoji ventilacija ili razmena svežeg vazduha
      na drugi način, postoji mogućnost stvaranja kondenza. Ova pojava će biti izraženija ukoliko nemate radijatore ili drugo grejno
      telo u blizini otvora, tj. ukoliko suv i topao vazduh ne suši stakla prozara ili vrata, ne koristite aspirator ili nemate nikakav vid 
      ventilacije u kupatilu.
      Postoje razna rešenja za gore navedenu situaciju, dok je najjednostavnije, a verovatno i najzdravije jednostavno povremeno otvoriti prozor, 
      prozračiti prostoriju i prirodnim putem smanjiti količinu vlage u vazduhu i obogatiti prostor kiseonikom.
      Koliko god nova stolarija dobro dihtovala i zadržavala topao vazduh unutar prostorije povremene razmene vazduha (ventilacijom ili prirodnim putem)
      su neophodne za održavanje zdravog životnog prostora.`,
    },
  ],
};
