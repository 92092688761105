import {
  YOUR_SERVICE_ID,
  YOUR_TEMPLATE_ID,
  YOUR_PUBLIC_KEY,
  accessToken,
} from "../key";
import axios from "axios";
const headers = {
  // Authorization: `Barer ${accessToken}`,
  "Content-Type": "application/json",
};

const sendMail = async (
  name: string,
  email: string,
  message: string,
  phone: string = ""
) => {
  var data = {
    service_id: YOUR_SERVICE_ID,
    template_id: YOUR_TEMPLATE_ID,
    user_id: YOUR_PUBLIC_KEY,
    template_params: {
      name: name,
      email: email,
      message: message,
      phone: phone,
    },
  };
  try {
    debugger;
    const response = await axios.post(
      "https://api.emailjs.com/api/v1.0/email/send",
      data,
      {
        headers,
      }
    );
    console.log("Email sent successfully:", response.data);
  } catch (error) {
    console.error("Error sending email:", JSON.stringify(error));
  }
};
export default sendMail;
